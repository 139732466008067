/** @jsx jsx */
import { jsx } from "@emotion/core";
import { rgba } from 'polished';
import { mq } from "../../../cssInJs";
//import { useState, useEffect } from "react";
//import env from "../../../env";
//import { SiteLink } from "../../../components"; //useRouter
//import { useHistory } from "react-router-dom";
//import { useForm } from "../../../form";
//import { fb, validators } from '../../../form';
//import { Button, ErrorMessage } from "../../../components";
//import { usePost } from "../../../components";
//import utils from "../../../utils";
//import style from "./productViewStyle";
//import { MdKeyboardArrowDown } from "react-icons/md";
//import { useStore } from "../../../store";

//import { MdKeyboardArrowDown } from "react-icons/md";
//import {ProductAttributes} from './productAttributes';

export function AddSubOptions({ product, option, changeOptionItems }) {
  const s = getListStyle();
  const options = option.options.length > 0 ? option.options.filter((x) => x.type === 2) : [];
  const paperThickness = options.find(x => x.title === 'Paper thickness');
  
  return (
    <div style={{marginTop : '2rem'}} >
      { options.map((x, index) => {
        return(
          <div key={index} className="subOptions__container" css={s.subOptions__container}>
             <div className={`subOptions__title ${x.error}`} css={s.subOptions__title}>
                {x.originTitle}
              </div>
              <div className="select__option__list" css={s.select__option__list}>
                { x.options.map((y, cIndex) => { 
                  if(x.title === 'Finish' && (y.value === 'Spot Gloss - Both Side' || y.value === 'Spot Gloss - One Side') && paperThickness.value === 'Premium - 420 gsm'){
                    return null;
                  }
                  return(
                    <div key={cIndex} className="select__option__list__item" css={s.select__option__list__item}>
                      <div className = {`select__option__list__item__title ${x.value === y.value ? 'selected' : 'unselected'}`} css={s.select__option__list__item__title} style={{border : '1px solid #ebebeb'}} onClick={() => {
                        changeOptionItems('changevalue', {id: x.id, value: y.value, quantity : y.quantity, price : y.price });
                      }}>
                        {y.name}
                        {/* {
                          y.price && y.price !== '' &&
                          <span className="select__option__list__item__price">{ y.price + "%" }</span> 
                        } */}
                      </div>
                    </div>
                  )
                })}
              </div>
              {
                x.error && x.error !== '' &&
                <div style={{marginLeft : '1rem', fontSize : '0.8rem', color : 'red', marginTop : '-0.5rem'}}> {'*' + x.errorMsg} </div>
              }
          </div>
        )
      })}
    </div>
  );
}

function getListStyle() {
  return {
    subOptions__container : {
      marginTop : '2rem',
      '&:first-of-type' : {
        marginTop : '3rem'
      }
    },
    
    subOptions__title : {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    select__option__list : {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft: '-0.5rem',
      marginRight: '-0.5rem'
    },
    select__option__list__item : mq({
      flex:  ['1 0 100%',null,'1 0 33.3%'],
      maxWidth: ['100%',null,'33.3%'],
      padding: '0.5rem',
      cursor: 'pointer',
      '.select__option__list__item__price' : {
        float: 'right'
      }
    }),
    select__option__list__item__title : {
      padding : '0.5rem 1rem',
      borderRadius: '0.25rem',
      fontWeight : 300,
      fontSize : '0.8rem',
      '&:hover' : {
        backgroundColor : rgba('#43B02A', 0.5),
        borderColor: rgba('#43B02A', 0.5),
        color : 'white'
      },
      '&.selected' : {
        backgroundColor: '#43B02A',
        borderColor: '#43B02A',
        color: 'white'
      }
    }
  };
}