//import variables from "../../../../cssInJs/variables";
//import { mq } from "../../../../cssInJs";

export default {
  popup__common: {
    position: "relative",
    "svg.close__btn": {
      position: "absolute",
      top: "5px",
      right: "10px",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    ".popup__common__title": {
      padding: "1rem",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
  },

  design__services__enquiry: {
    padding: "1rem",
    ".dse__img__container": {
      maxWidth: "320px",
      margin: "auto",
    },
    ".dse__title": {
      fontWeight: 700,
      fontSize: "1.45rem",
      textAlign: "center",
      marginTop: "2rem",
    },
    ".dse__inform__container": {
      marginTop: "2.5rem",
      ".dse__inform__title": {
        fontWeight: 700,
        marginBottom: "0.5rem",
        //marginBottom : '1rem'
      },
      ".dse__inform__item": {
        display: "flex",
        flexDirection: "row",
        // '&.type__2:first-of-type' : {
        //   marginTop : '2rem'
        // },
        ".dse__inform__item_1": {
          flex: "1 0 30%",
          maxWidth: "30%",
          fontWeight: 600,
        },
        ".dse__inform__item_2": {
          flex: "1 0 70%",
          maxWidth: "70%",
        },
      },
    },

    ".contact__inform__container": {
      marginLeft: "-0.25rem",
      marginRight: "-0.25rem",
      "div.form-group": {
        flex: "1 0 50%",
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem",
      },
    },

    ".contact__inform__buttons": {
      display: "flex",
      flexDirection: "row",
      marginLeft: "-0.5rem",
      marginRight: "-0.5rem",
      ".contact__inform__button": {
        flex: "1 0 50%",
        maxWidth: "50%",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        "button.btn": {
          width: "50%",
          backgroundColor: "white",
          color: "black",
          borderColor: "black",
          "&:focus": {
            boxShadow: "none",
            // color : 'white',
            // backgroundColor: "#43B02A",
            // borderColor : "#43B02A"
          },
          "&:hover": {
            color: "white",
            backgroundColor: "#43B02A",
            borderColor: "#43B02A",
          },
        },
        "button.btn.p": {
          backgroundColor: "#43B02A",
          borderColor: "#43B02A",
          color: "white",
          "&:hover": {
            backgroundColor: "#0e0e0e",
            borderColor: "#080808",
          },
        },
      },
    },
  },
};
