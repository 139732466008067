/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
//import { MdClose, MdFileUpload } from "react-icons/md";
//import { Button } from "../../../../components";

export function DesignServicesEnquiry({ changeOptionItems, option, product }) {
  const options1 =
    option.options.length > 0 ? option.options.filter((x) => x.type === 1) : [];
  const options2 =
    option.options.length > 0 ? option.options.filter((x) => x.type === 2) : [];

  return (
    <div
      className="popup__common"
      css={[style.popup__common, style.design__services__enquiry]}
    >
      <div className="dse__img__container">
        <img src="/assets/logos/logo.png" width="100%" alt="logo" />
      </div>
      <div className="dse__title">Design Services Enquiry</div>
      <div className="dse__inform__container">
        <div className="dse__inform__title">Your information:</div>

        <div className="dse__inform__item">
          <div className="dse__inform__item_1">Product :</div>
          <div className="dse__inform__item_2">{product.productName}</div>
        </div>

        {options1.map((item, index) => {
          return (
            <div key={index} className={`dse__inform__item`}>
              <div className="dse__inform__item_1">{item.title} :</div>
              <div className="dse__inform__item_2">{item.value}</div>
            </div>
          );
        })}

        <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}></div>

        {options2.map((item, index) => {
          return (
            <div key={index} className={`dse__inform__item`}>
              <div className="dse__inform__item_1">{item.title} :</div>
              <div className="dse__inform__item_2">{item.value}</div>
            </div>
          );
        })}
      </div>

      <form
        onSubmit={(e) => {
          changeOptionItems("sendenquiry", { e: e });
        }}
      >
        <div
          style={{
            marginTop: "1.5rem",
            marginBottom: "0.5rem",
            fontWeight: 700,
          }}
        >
          Your contact information:
        </div>

        <div style={{ display: "flex" }} className="contact__inform__container">
          <div className="form-group">
            <label className="label" style={{ marginBottom: "0rem" }}>
              Full Name*
            </label>
            <div className="input-group">
              <input
                required
                className="form-control"
                type="text"
                value={option.contactName}
                name="contactName"
                onChange={(e) => {
                  changeOptionItems("changeinform", { e: e });
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label" style={{ marginBottom: "0rem" }}>
              Email*
            </label>
            <div className="input-group">
              <input
                required
                className="form-control"
                type="email"
                value={option.contactEmail}
                name="contactEmail"
                onChange={(e) => {
                  changeOptionItems("changeinform", { e: e });
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="label" style={{ marginBottom: "0rem" }}>
            Your Message (Optional)
          </label>
          <div className="input-group">
              <textarea 
                className="form-control"
                value={option.yourMessage}
                name="yourMessage"
                rows="2"
                onChange={(e) => {
                  changeOptionItems("changeinform", { e: e });
                }}
              />
            </div>
        </div>

        <div style={{ display: "flex" }} className="contact__inform__buttons">
          <div
            className={`contact__inform__button`}
            style={{ textAlign: "right" }}
          >
            <button
              onClick={() => {
                changeOptionItems("closepopup");
              }}
              type="button"
              className="btn btn-primary"
            >
              Cancel
            </button>
          </div>
          <div
            className={`contact__inform__button ${
              option.delivery === "No" ? "selected" : ""
            }`}
            style={{ textAlign: "left" }}
          >
            <button className="btn btn-primary p" type="submit">
              Send enquiry
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}