import { mq } from "../../../cssInJs";

export default {
  pageTiles_small2: {
    margin: "auto",
    marginTop: "0",
    marginBottom: "2rem",
  },

  pageTiles_small2_warpper: mq({
    display: "flex",
    // marginLeft: "-0.5rem",
    // marginRight: "-0.5rem",
    flexWrap: "wrap",
    "&.useSlider": {
      display: "block",
      marginLeft: "0",
      marginRight: "0",
    },
  }),

  pageTile_small2: mq({
    flex: ["0 0 100%", null, "0 0 25%", null, null, "0 0 25%"],
    maxWidth: ["100%", null, "25%", null, null, "25%"],
    padding: "0.5rem 1rem",

    '&:nth-child(odd)' : mq({
      paddingLeft: ['1rem', null, '1rem'],
      paddingRight: ['1rem', null, '1rem'],
      //paddingRight: ['0.5rem', null, '1rem'],
    }),

    '&:nth-child(even)' : mq({
      //paddingLeft: ['0.5rem', null, '1rem'],
      paddingLeft: ['1rem', null, '1rem'],
      paddingRight: ['1rem', null, '1rem'],
    }),

    "&.useSlider": {
      //flex : '0 0 100%',
      maxWidth: "372px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    ".pageTile_small2_link": {
      boxShadow: "6px 8px 9px 3px rgba(190,190,190,0.5)",
      display: "block",
      borderRadius: "20px",
      // overflow: "hidden",
      background: "#F8F8F8",
      "&:hover": {
        textDecoration: "none",
        ".pageTile_small2__bg": {
          transform: "scale(1.05)",
        },
      },
    },
    ".pageTile_small2__bg": {
      paddingTop: "80%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      transition: "all .2s",
      borderRadius: "20px 20px 0 0",
    },
    ".pageTile_small2__title": {
      margin: ".5rem 1rem 1rem",
      color: "black",
      WebkitLineClamp: "1",
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      height: "2.5rem",
      lineHeight: "2",
      "svg": {
        marginLeft: "0.5rem",
        marginTop: "-2px",
      },
    },
  }),
};
