/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./productViewStyle";
import React, { useState, useEffect, Fragment } from "react";
import env from "../../../env";
import utils from "../../../utils";
import {
  ErrorPopup,
  Loading,
  Button,
  //ErrorMessage,
  useGet,
  usePost,
  useRouter,
  //SiteLink,
} from "../../../components";
import { ViewPartial } from "../../../cms/pages/partials/viewPartial";
//import { CommonProductBottom } from '../../../cms/pages/partials/commonProductBottom';
import { ProductTile } from "../productList/productTile";
import FsLightbox from "fslightbox-react";
import { MetaTag } from "../../../components";
//import { AddToCart } from "./addToCart";
import { AddOptions } from "./addOptions";
import { AddOptions2 } from "./addOptions2";
import { AddSubOptions } from "./addSubOptions";
import Slider from "react-slick";
//import { MdFavorite } from "react-icons/md";
//import { debounce } from '../../../lib/utils-core/debounce';
import { gtm } from "../../../lib/tracking";
import { MdArrowForward } from "react-icons/md";
// import {
//   FacebookShareButton,
//   FacebookIcon,
//   PinterestShareButton,
//   PinterestIcon,
// } from "react-share";
import { createPortal } from "react-dom";
//import { getEnquiryFormModel } from "./enquiryFormModel";
//import { useForm } from "../../../form";
import { ProductPagePartial } from "../../../cms/pages/partials/productPagePartial";
import { PopupError } from "./popupError/popupError";
import { AlmostFinished } from "./almostFinished/almostFinished";
import { DesignServicesEnquiry } from "./designServicesEnquiry/view";
import { DesignServicesThankyou } from "./designServicesThankyou/view";
import { SelectAnImage } from "./SelectAnImage/view";
import { DesignReview } from "./designReview/view";
import { AddedToCartView } from "./addedToCartView/view";
import { useStore } from "../../../store";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductView(props) {
  const { query, navigate } = useRouter();
  const [loaded, setLoaded] = React.useState(false);
  const get = useGet();
  const productCode = query.id || "";
  const category = query.category || "";
  const slug = query.slug || "";
  //console.log(category, slug, productCode)
  React.useEffect(() => {
    //const url = productCode ? `/api/product/getProduct/${productCode}` : `/api/product/getProductByUrl?category=${category}&slug=${slug}`
    const url = `/api/product/getProduct/${slug}`;
    get.send(env.apiBase + url);
    setLoaded(false);
    // eslint-disable-next-line
  }, [productCode, slug]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  const data = get.response;
  
  if (get.done() && !loaded) {
    setLoaded(true);
    const pageUrl = productCode
      ? `/product/${productCode}`
      : `/products/${category}/${slug}`;
    const pageTitle =
      data.totalCnt === 0 ? "No product found" : data.productName;
    gtm.pageView(pageUrl, pageTitle);
  }

  if (data.totalCnt === 0) return <div>No product found.</div>;
  return (
    <React.Fragment>
      <Product product={data} navigate={navigate} />
      <ProductPagePartial />
      <ViewPartial />
    </React.Fragment>
  );
}

function Product({ product, navigate }) {
  const mainPhoto =
    product.productImages.length > 0
      ? {
          imageUrl:
            product.productImages[0].imageUrl,
          alt: product.productImages[0].alt
            ? product.productImages[0].alt
            : product.productName,
        }
      : { imageUrl: "/assets/logos/logo.png", alt: product.productName };

  const allPhotos = product.productImages || [];
  const { dispatch } = useStore();
  //console.log("aaa", allPhotos);
  //const otherPhotos = product.productImages.length > 0 ? product.productImages.filter(x => !x.isPackage) : [];
  const popupImages = allPhotos.map((x) => x.imageUrl);
  const _cartId = env.getDevCartId();
  const [heroImage, setHeroImage] = React.useState(mainPhoto);
  //const [openPopup, setOpenPopup] = useState(false);
  const [option, setOption] = useState({
    productName: product.productName,
    cartId: _cartId === "null" ? null : _cartId,
    productId: product.productId,
    productCode: product.productCode,
    thumbnailUrl: product.thumbnailUrl,
    stageLoading: false,
    currentStage: 1,
    popup: false,
    popupType: "",
    design: "",
    delivery: "No",
    contactName: "",
    contactEmail: "",
    yourMessage : "",
    totalEachPrice: (0).toFixed(2),
    totalPrice: (0).toFixed(2),
    firstFile: null,
    secondFile: null,
    postDoneType: "",
  });

  const [toggler, setToggler] = React.useState(false);
  const [popupIndex, setPopupIndex] = React.useState(0);
  const filePondRef = React.useRef(null);
  //const enquiryFormModel = getEnquiryFormModel({});
  //const enquiryForm = useForm(enquiryFormModel, { usePlaceholder: true });

  //const enquiryFormRender = (name) => enquiryForm.renderControl(name, null);
  const post = usePost();
  //const [sendingStatus, setSendingStatus] = React.useState(null);
  //const [sendingStatus2, setSendingStatus2] = React.useState(null);
  //const breadCrumbs = product.breadCrumbs;

  function openPopupImage(imageUrl) {
    const inx = utils.array.findIndex(popupImages, (x) => x === imageUrl);
    setPopupIndex(inx >= 0 ? inx : 0);
    setToggler(!toggler);
  }

  var settings = {
    dots: false,
    infinite: allPhotos.length > 2 ? true : false,
    speed: 1500,
    autoplaySpeed: 3000,
    //fade: true,
    autoplay: false,
    slidesToShow: allPhotos.length >= 3 ? 3 : allPhotos.length,
    slidesToScroll: 1,
  };

  const slides = allPhotos.map((photo, index) => (
    <div
      css={style.productViewImage}
      key={index}
      className={`productView__image ${
        heroImage.imageUrl === photo.imageUrl ? "current" : ""
      }`}
    >
      <div
        css={style.productViewImageBg}
        className={`productView__imageBg`}
        style={{
          backgroundImage: utils.css.bgUrlStyle(photo.imageUrl),
        }}
        onClick={() => setHeroImage(photo)}
      ></div>
      {/* <img src={utils.site.resourcePath(photo.imageUrl)} alt={photo.alt} onClick={() => setHeroImage(photo)} /> */}
    </div>
  ));

  // function onSubmit(e) {
  //   enquiryForm.validateForm(e, () => {
  //     const value = {
  //       ...enquiryForm.getValue(),
  //       productName: product.productName,
  //       emailReceiver: "",
  //     };
  //     //var files = form.getFiles();
  //     var formData = new FormData();
  //     formData.append("jsonData", JSON.stringify(value));
  //     //post.send(env.apiBase + "/api/contact/send", value);
  //     post.sendFormData(env.apiBase + "/api/cart/sendenquire", formData);
  //     setSendingStatus("pending");
  //   });
  //   console.log("submitting");
  // }

  function sendDesignServicesEnquiry() {
    const model = {
      productName: product.productName,
      contactName: option.contactName,
      contactEmail: option.contactEmail,
      yourMessage : option.yourMessage,
      options: option.options,
    };

    post.send(env.apiBase + "/api/cart/senddesignservicesenquiry", model);
    changeOptionItems("sendDesignServicesEnquiry");
  }

  function addToCart() {
    post.send(env.apiBase + "/api/cart/addToCart", option);
    changeOptionItems("addToCart");
  }

  if (post.done() && option.stageLoading) {
    

    
    setTimeout(() => {
      if (option.postDoneType === "sendenquiry") {
        changeOptionItems("donePost");
      } else if (option.postDoneType === "addtocart") {
        changeOptionItems("donePostAddToCart");
        env.setDevCartId(post.response.results.cartInfo.cartId);
        dispatch({
          type: "SET_STATE",
          payload: {
            cartInfo:
              post.response.results.cartInfo.numberOfItems === 0
                ? 0
                : post.response.results.cartInfo.numberOfItems,
          },
        });
        dispatch({
          type: "SET_STATE",
          payload: { cartItemAmount: post.response.results.cartInfo.itemAmount === 0 ? 0 : post.response.results.cartInfo.itemAmount },
        });
      }
    }, 1500);
  } else if (post.hasErrors() && option.stageLoading) {
    setTimeout(() => {
      changeOptionItems("postError");
    }, 1500);
  }

  function changeOptionItems(functionType, { ...props }) {
    switch (functionType) {
      case "open":
        const optionItem = option.options.find((x) => x.id === props.id);
        if (optionItem) {
          const ddd = optionItem.open;
          option.options.forEach((el) => {
            el.open = false;
          });
          optionItem.open = !ddd;
        }
        break;
      case "changevalue":
        const optionItem_cv = option.options.find((x) => x.id === props.id);
        if (optionItem_cv) {
          optionItem_cv.error = "";
          optionItem_cv.value = props.value;
          optionItem_cv.quantity = props.quantity;
          optionItem_cv.price = props.price;
          option.options.forEach((el) => {
            el.open = false;
          });
        }

        const paperThickness = option.options.find(
          (x) => x.title === "Paper thickness"
        );

        if (paperThickness && paperThickness.value === "Premium - 420 gsm") {
          const finish = option.options.find((x) => x.title === "Finish");
          finish.value = "Standard";
        }

        break;
      case "contiune1":
        let checkError = false;
        option.options.forEach((el) => {
          if (el.value === "" && el.type === 1) {
            el.error = "error";
            el.errorMsg = "Please select " + el.title;
            checkError = true;
          }
        });

        if (checkError === true) {
          //option.popup = true;
          option.popupType = "error";
          //document.body.style.overflow = "hidden";
        } else {
          //option.popup = false;
          option.popupType = "stageloading";
          option.stageLoading = true;
          option.currentStage = 2;
        }
        break;
      case "contiune2":
        let checkError_2 = false;
        option.options.forEach((el) => {
          if (el.value === "" && el.type === 2) {
            el.error = "error";
            el.errorMsg = "Please select a" + el.title;
            checkError_2 = true;
          }
          if (checkError_2 === true) {
            //option.popup = true;
            option.popupType = "error";
            //document.body.style.overflow = "hidden";
          } else {
            //option.popup = true;
            option.popupType = "almostfinished";
            //document.body.style.overflow = "hidden";
            //option.stageLoading = true;
            //option.currentStage = 2;
          }
        });
        break;
      case "closepopup":
        option.design = "";
        option.postDoneType = "";
        option.contactName = "";
        option.contactEmail = "";
        option.popup = false;
        option.popupType = "";
        option.stageLoading = false;
        option.firstFile = null;
        document.body.style.overflow = "unset";
        break;
      case "design":
        option.firstFile = null;
        option.design = props.type;
        break;
      case "delivery":
        option.delivery = props.type;
        break;
      case "sendenquiry":
        props.e.stopPropagation();
        props.e.preventDefault();
        option.postDoneType = "sendenquiry";
        sendDesignServicesEnquiry();
        break;
      case "callAddToCart":
        props.e.stopPropagation();
        props.e.preventDefault();
        option.postDoneType = "addtocart";
        addToCart();
        break;
      case "changeinform":
        option[props.e.target.name] = props.e.target.value;
        break;
      case "backtohome":
        document.body.style.overflow = "unset";
        setTimeout(() => {
          navigate("/");
        }, 500);
        return;
      case "gotocart":
        document.body.style.overflow = "unset";
        setTimeout(() => {
          navigate("/cart");
        }, 500);
        return;
      case "gotoshopping":
        document.body.style.overflow = "unset";
        setTimeout(() => {
          navigate("/online-order");
        }, 500);
        return;
      case "uploadfiles":
        if (props.type === "firstFile") {
          option.firstFile = props.file;
        } else if (props.type === "secondFile") {
          option.secondFile = props.file;
        }
        return;
      case "finishuploadfiles": 
        option.popupType = "selectanimage2";
        if(option.options && option.options.length > 0 ){
          const printingOption = option.options.find(x => x.title === 'Printing');
          if(printingOption && printingOption.value === 'One sided full colour'){
            option.popupType = "designreview";
          }
        }
        break;
      case "finishuploadfiles2":
        option.popupType = "designreview";
        break;
      case "sendDesignServicesEnquiry":
        option.popupType = "stageloading";
        option.stageLoading = true;
        break;
      case "addToCart":
        option.popupType = "stageloading";
        option.stageLoading = true;
        break;
      case "donePost":
        option.popupType = "designservicesthankyou";
        option.stageLoading = false;
        option.postDoneType = "";
        option.design = "";
        option.contactName = "";
        option.contactEmail = "";
        break;
      case "donePostAddToCart":
        option.popupType = "addedtocartview";
        option.stageLoading = false;
        option.postDoneType = "";
        option.design = "";
        option.contactName = "";
        option.contactEmail = "";
        break;
      case "postError":
        option.popupType = "posterror";
        option.stageLoading = false;
        option.postDoneType = "";
        option.design = "";
        option.contactName = "";
        option.contactEmail = "";
        option.firstFile = null;
        option.secondFile = null;
        break;
      case "changedesign":
        //option.design = "Upload your design";
        option.popupType = "selectanimage";
        break;
      default:
        break;
    }

    if (
      option.design &&
      option.design === "Need Design Services" &&
      option.delivery &&
      option.delivery !== ""
    ) {
      option.popupType = "designservicesenquiry";
    } else if (
      option.design &&
      option.design === "Upload your design" &&
      option.delivery &&
      option.delivery !== "" &&
      option.popupType !== "designreview" &&
      option.popupType !== "selectanimage2" &&
      option.popupType !== "addedtocartview" &&
      option.popupType !== "stageloading" &&
      option.popupType !== "error"
    ) {
      option.popupType = "selectanimage";
    }

    if (option.popupType && option.popupType !== "") {
      option.popup = true;
      document.body.style.overflow = "hidden";
    }

    let totalQuantity = 0;
    let totalPrice = 0; // Number(0).toPrecision(2)

    option.options.forEach((el) => {
      if (el.quantity && el.quantity !== "") {
        totalQuantity = totalQuantity + parseInt(el.quantity);
      }

      if (el.price && el.price !== "") {
        totalPrice = totalPrice + parseFloat(el.price);
      }
    });

    if (totalQuantity !== 0 && totalPrice !== 0) {
      //option.totalEachPrice = (totalPrice / totalQuantity).toFixed(2); 
      const _totalEachPrice = (((totalPrice / 100) * product.priceIncTax) / totalQuantity ).toFixed(2);
      option.totalEachPrice = _totalEachPrice;
      option.totalPrice = _totalEachPrice * totalQuantity;
    }
    setOption({ ...option });
  }

  useEffect(() => {
    if (product && product.optionItems) {
      const result = product.optionItems.map((x) => {
        let abc = [];
        if (x.items && x.items.length > 0) {
          abc = x.items.map((y) => {
            const _price = y.itemOptions.find((x) => x.name === "price");
            const _quantity = y.itemOptions.find((x) => x.name === "quantity");
            const value =
              y.title +
              (y.specailOption && y.specailOption !== ""
                ? " ($" + y.specailOption + "/ unit)"
                : "");
            return {
              name: value,
              value: value,
              price: _price.value,
              quantity: _quantity ? _quantity.value : "",
              preSelected: y.preSelected,
            };
          });
        }

        const preSelected = abc.find((x) => x.preSelected === true);

        return {
          title: x.title,
          originTitle: x.speicalTitle,
          multiple: x.multiple,
          type: x.type,
          value: preSelected ? preSelected.value : "",
          open: false,
          options: [...abc],
          error: "",
          errorMsg: "",
          price: preSelected ? preSelected.price : "",
          quantity: preSelected ? preSelected.quantity : "",
          id: utils.site.getRandomText(),
        };
      });

      // let totalQuantity = 0;
      // let totalPrice = 0;
      // result.forEach(el => {
      //   if (el.quantity && el.quantity !== "") {
      //     totalQuantity = totalQuantity + parseInt(el.quantity);
      //   }

      //   if (el.price && el.price !== "") {
      //     totalPrice = totalPrice + parseFloat(el.price);
      //   }
      // });

      setOption({ ...option, options: result });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer1;
    if (option.stageLoading) {
      timer1 = setTimeout(() => {
        //option.stageLoading = false;
        //option.popupType = "";
        changeOptionItems("closepopup");
        //setOption({ ...option });
      }, 1500);
    }

    const ddd = document.querySelector("#productView__each__price__id");
    const ddd2 = document.querySelector("#productView__each__price__id__2");

    ddd2.classList.add("visibility");

    const handleScroll = () => {
      if (ddd) {
        const rect = ddd.getBoundingClientRect();
        if (
          rect.top >= 113 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          ddd2.classList.remove("visibility");
        } else {
          ddd2.classList.add("visibility");
        }
      }
    };

    handleScroll();
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);

    return () => {
      if (timer1) {
        clearTimeout(timer1);
      }
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line
  }, [option.currentStage]);

  // if (option.stageLoading) {
  //   return (
  //     <div className="contentPanel productViewPanel">
  //       <div className="contentPanel__bg"></div>
  //       <div className="container" style={{ marginTop: "56px" }}>
  //         <Loading />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="contentPanel productViewPanel">
      <Portal>
        {(option.popup || option.stageLoading) && (
          <Fragment>
            <div
              className="portal__background"
              // onClick={() => {
              //   changeOptionItems("closepopup");
              // }}
            ></div>
            <div className={`portal__popupcontainer ${option.popupType}`}>
              {option.popupType === "error" && (
                <PopupError changeOptionItems={changeOptionItems} />
              )}
              {option.popupType === "almostfinished" && (
                <AlmostFinished
                  changeOptionItems={changeOptionItems}
                  option={option}
                />
              )}
              {option.popupType === "designservicesenquiry" && (
                <DesignServicesEnquiry
                  changeOptionItems={changeOptionItems}
                  option={option}
                  product={product}
                />
              )}
              {option.popupType === "designservicesthankyou" && (
                <DesignServicesThankyou changeOptionItems={changeOptionItems} />
              )}
              {option.popupType === "selectanimage" && (
                <SelectAnImage
                  changeOptionItems={changeOptionItems}
                  filePondRef={filePondRef}
                  option={option}
                  title="Select an front image"
                  functionName="finishuploadfiles"
                  type="firstFile"
                />
              )}
              {option.popupType === "selectanimage2" && (
                <SelectAnImage
                  changeOptionItems={changeOptionItems}
                  filePondRef={filePondRef}
                  option={option}
                  title="Select an back image"
                  functionName="finishuploadfiles2"
                  type="secondFile"
                />
              )}
              {option.popupType === "designreview" && (
                <DesignReview
                  changeOptionItems={changeOptionItems}
                  filePondRef={filePondRef}
                  option={option}
                />
              )}
              {option.popupType === "addedtocartview" && (
                <AddedToCartView
                  changeOptionItems={changeOptionItems}
                  option={option}
                />
              )}
              {option.popupType === "posterror" && (
                <ErrorPopup title="Loading Error" errors={post.errors} />
              )}
              {option.stageLoading && option.popupType === "stageloading" && (
                <Loading />
              )}
            </div>
          </Fragment>
        )}
      </Portal>

      <FsLightbox
        toggler={toggler}
        sources={popupImages}
        sourceIndex={popupIndex}
      />
      <MetaTag data={getMetaTag(product, mainPhoto)} />

      <div className="contentPanel__bg"></div>
      <div className="container">
        {/* <Breadcrumb breadcrumb={product.breadcrumb} /> */}
        <div className="productView">
          <div
            style={
              {
                //flex: "1 0 100%",
                //maxWidth: "100%",
                //paddingLeft: "1rem",
                //justifyContent: "end",
                //alignItems: "end",
              }
            }
            css={style.productView__each__price__id__2}
            className={`productView__each__price__id__2 ${
              option.currentStage === 2 || option.currentStage === 3
                ? "stage2"
                : ""
            }`}
            id="productView__each__price__id__2"
          >
            <div
              className="productView__each__price stick"
              css={style.productView__each__price}
              style={{ backgroundColor: "white", zIndex: "9999" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    className="dollar_icon"
                    style={{
                      borderRadius: "2rem",
                      color: "#43B02A",
                    }}
                  >
                    {" "}
                    ${" "}
                  </span>
                </div>
              </div>
              <div
                className="price_num"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                }}
              >
                {option.totalEachPrice}
              </div>
              <div
                style={{
                  paddingLeft: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="inc_gst_con"
              >
                <div style={{ fontSize: "0.7rem", lineHeight: "1" }}>
                  inc GST
                </div>
                <div
                  style={{
                    color: "#43B02A",
                    lineHeight: "1",
                    //fontSize: "1.1rem",
                    marginTop: "2px",
                  }}
                  className="each_text"
                >
                  each
                </div>
              </div>
            </div>
          </div>

          <div itemScope={true} itemType="http://schema.org/Product">
            <meta itemProp="name" content={product.productName} />
            <div itemProp="brand" itemType="http://schema.org/Brand" itemScope>
              <meta itemProp="name" content={env.siteName} />
            </div>

            {product.description && (
              <meta itemProp="description" content={product.description} />
            )}
            {/* {barcodeCleaned && <meta itemProp="sku" content={barcodeCleaned} />} */}
            {heroImage && heroImage.imageUrl && (
              <link
                itemProp="image"
                href={env.baseOrigin + heroImage.imageUrl}
              />
            )}

            <div className="row" style={{ marginTop: "-54px" }}>
              {/* <div className='col-md-10' css={style.productPageCol}>
                <ul className='breadCrumbs' css={style.productBreadCrumbs}>
                  <li>
                    {" "}
                    <SiteLink to='/'> Home </SiteLink>{" "}
                  </li>
                  <li>
                    {" "}
                    <SiteLink to='/shop'> Shop </SiteLink>{" "}
                  </li>
                  {breadCrumbs.map((item, index) => (
                    <li
                      key={index}
                      className={`breadCrumb ${
                        index === breadCrumbs.length - 1 ? "last-item" : ""
                      }`}
                    >
                      <SiteLink to={item.categoryPageUrl}>
                        {" "}
                        {item.categoryPageTitle}{" "}
                      </SiteLink>
                    </li>
                  ))}
                  <li>{product.productName}</li>
                </ul>
              </div> */}

              <div
                className={`${
                  option.currentStage === 2 || option.currentStage === 3
                    ? "col-md-4"
                    : "col-md-6"
                }  col-12`}
                css={style.productPageCol}
              >
                <div
                  css={style.heroImageContainer}
                  className="productView__hero__container"
                >
                  <img
                    css={style.heroImage}
                    className="productView__hero"
                    src={heroImage.imageUrl}
                    alt={heroImage.alt}
                    width="100%"
                    onClick={() => openPopupImage(heroImage.imageUrl)}
                  />
                </div>

                {allPhotos && allPhotos.length > 1 && (
                  <div
                    css={style.productViewImages}
                    className="productView__images"
                  >
                    {/* {slides} */}
                    <Slider {...settings}>{slides}</Slider>
                  </div>
                )}

                {option && option.currentStage === 1 && (
                  <div
                    css={style.desc}
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    style={{ marginTop: "1.5rem", maxWidth: "820px" }}
                  ></div>
                )}
              </div>

              <div
                className={`${
                  option.currentStage === 2 || option.currentStage === 3
                    ? "col-md-8"
                    : "col-md-6"
                } col-md-6 col-12`}
                css={style.productPageCol}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="productView__title__container"
                  css={style.productView__title__container}
                >
                  <div
                    style={{
                      flex: "1 0 100%",
                      maxWidth: "100%",
                      paddingLeft: "1rem",
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                    id="productView__each__price__id"
                  >
                    <div
                      className="productView__each__price"
                      css={style.productView__each__price}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              borderRadius: "2rem",
                              color: "#43B02A",
                            }}
                            className="dollar_icon"
                          >
                            {" "}
                            ${" "}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "600",
                        }}
                        className="price_num"
                      >
                        {option.totalEachPrice}
                      </div>
                      <div
                        style={{
                          paddingLeft: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        className="inc_gst_con"
                      >
                        <div style={{ lineHeight: "1" }} className="inc_gst">
                          inc GST
                        </div>
                        <div
                          style={{
                            color: "#43B02A",
                            lineHeight: "1",
                            // fontSize: "1.1rem",
                            marginTop: "2px",
                          }}
                          className="each_text"
                        >
                          each
                        </div>
                      </div>
                    </div>
                  </div>

                  <h1
                    className="productView__title"
                    css={style.productViewTitle}
                  >
                    <strong>{product.productName}</strong>
                  </h1>
                </div>

                <div
                  css={style.desc}
                  dangerouslySetInnerHTML={{
                    __html: product.secondDescription,
                  }}
                ></div>

                {/* {product.productStatus === 0 && <AddToCart product={product} />} */}

                {product.productStatus === 0 &&
                  option &&
                  option.options &&
                  option.currentStage === 1 && (
                    <Fragment>
                      <AddOptions
                        product={product}
                        option={option}
                        changeOptionItems={changeOptionItems}
                      />
                    </Fragment>
                  )}

                {product.productStatus === 0 &&
                  option &&
                  option.currentStage === 1 && (
                    <div className="contiune__button_con">
                      <Button
                        className="contiune__button"
                        css={style.contiune__button}
                        onClick={() => {
                          changeOptionItems("contiune1");
                        }}
                      >
                        Continue
                        <MdArrowForward style={{ marginLeft: "0.5rem" }} />
                      </Button>
                    </div>
                  )}

                {option && option.currentStage === 1 && (
                  <div style={{ marginTop: "1rem" }}>
                    <h3 css={style.p_templates}>Product Specs & Templates</h3>
                    <div css={style.p_templates_img_co}>
                      <div
                        className="current__stage__1"
                        css={style.p_templates_img_co_1}
                      >
                        <div className="p_templates_img_co_1_1">
                          <img
                            src="/assets/thp-business-card-tempate.png"
                            alt="logo"
                            width="100%"
                          />
                        </div>
                        <div className="p_templates_img_co_1_2">
                          <div className="p_templates_img_co_1_2_i">
                            <div className="p_templates_img_co_1_2_i_1">
                              {" "}
                              Safety Area{" "}
                            </div>
                            <div className="p_templates_img_co_1_2_i_2">
                              {/* <div>3.46" X 2.05"</div> */}
                              <div>86mm X 51mm</div>
                            </div>
                          </div>

                          <div className="p_templates_img_co_1_2_i">
                            <div className="p_templates_img_co_1_2_i_1">
                              {" "}
                              Trim Size{" "}
                            </div>
                            <div className="p_templates_img_co_1_2_i_2">
                              {/* <div>3.58" X 2.17"</div> */}
                              <div>90mm X 55mm</div>
                            </div>
                          </div>

                          <div className="p_templates_img_co_1_2_i">
                            <div className="p_templates_img_co_1_2_i_1">
                              {" "}
                              Full Bleed Size{" "}
                            </div>
                            <div className="p_templates_img_co_1_2_i_2">
                              {/* <div>3.7" X 2.28"</div> */}
                              <div>94mm X 59mm</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="current__stage__1"
                        css={style.p_templates_img_co_2}
                      >
                        <div>
                          <p>
                            To avoid white edges, extend your design to the full
                            bleed size but keep text and images within the
                            safety area.
                          </p>
                          <p style={{ marginTop: "1rem" }}>
                            Create your print-ready file. Download the template.
                          </p>
                          <div css={style.buttons}>
                            <a
                              href="/assets/thps_business_card_template.ai"
                              className="button"
                              download
                            >
                              PDF
                            </a>
                            <a
                              href="/assets/THP-Business-card-tempate.jpg"
                              className="button"
                              download
                            >
                              SVG
                            </a>
                            <a
                              href="/assets/thps_business_card_template.svg"
                              className="button"
                              download
                            >
                              Adobe AI
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {product.productStatus === 0 &&
                  option &&
                  option.options &&
                  (option.currentStage === 2 || option.currentStage === 3) && (
                    <Fragment>
                      <h1
                        className="productView__title__2"
                        style={{ marginTop: "2rem" }}
                        css={style.productViewTitle__2}
                      >
                        <strong> Business Cards </strong>
                      </h1>

                      <AddOptions2
                        product={product}
                        option={option}
                        changeOptionItems={changeOptionItems}
                      />
                    </Fragment>
                  )}

                {/* {product.productStatus === 100 && (
                  <Button
                    onClick={() => {
                      setOpenPopup(true);
                      document.body.style.overflow = "hidden";
                    }}
                  >
                    {" "}
                    ENQUIRE NOW{" "}
                  </Button>
                )} */}

                {product.productStatus === 1 && (
                  <Button disabled> SOLD OUT </Button>
                )}

                {product.productStatus === 98 && (
                  <Button disabled> NOT AVAILABLE </Button>
                )}
              </div>

              {(option.currentStage === 2 || option.currentStage === 3) && (
                <Fragment>
                  <div className="col-12">
                    <AddSubOptions
                      product={product}
                      option={option}
                      changeOptionItems={changeOptionItems}
                    />
                  </div>
                  <div className="col-12" style={{ marginTop: "2rem" }}>
                    <div style={{ textAlign: "right" }}>
                      <div
                        className="total__price__text"
                        css={style.total__price__text}
                      >
                        Total price : ${option.totalPrice}
                      </div>

                      <Button
                        className="contiune__button"
                        css={style.contiune__button}
                        onClick={() => {
                          changeOptionItems("contiune2");
                        }}
                      >
                        Continue
                        <MdArrowForward style={{ marginLeft: "0.5rem" }} />
                      </Button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>

            <RelatedProducts products={product.relatedProducts} />
          </div>
        </div>
      </div>
    </div>
  );
}

function getMetaTag(product, mainPhoto) {
  return {
    title: product.productName,
    description: product.metaDesc || product.intro,
    keywords: "",
    heroImageUrl: utils.site.resourcePath(mainPhoto.imageUrl),
    language: "English",
    country: "Australia",
    siteName: env.siteName,
    url: utils.site.resourcePath("/product/" + product.productSlug),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}

function RelatedProducts({ products }) {
  //console.log(products)
  if (!products || products.length === 0) return null;
  return (
    <div className="productView__relatedProducts">
      <h3>RELATED PRODUCTS</h3>
      <div className="productList">
        {products.map((product, index) => (
          <ProductTile
            product={product}
            key={product.productCode}
          ></ProductTile>
        ))}
      </div>
    </div>
  );
}
